import {useCallback, useEffect, useState} from 'react';
import {SearchIcon} from '@shopify/polaris-icons';
import {useLocation} from '@remix-run/react';
import cn from 'classnames';

import {type I18NOptions} from '@/types';
import {default as GlobalHeader} from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';

import SearchOverlay from './SearchOverlay';

export default function Header() {
  const {t: tNews} = useTranslations();
  const {pathname} = useLocation();
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);

  useEffect(() => {
    (
      document.querySelectorAll(
        'header ul li a',
      ) as unknown as HTMLAnchorElement[]
    ).forEach((link) => {
      link.href.endsWith(pathname) && link.classList.add('active-link');
    });
  }, [pathname]);

  const headerLinks = [
    {
      content: 'news:redesign.nav.latest',
      url: '/news',
    },
    {
      content: 'news:redesign.nav.company',
      url: '/news/category/company-news',
    },
    {
      content: 'news:redesign.nav.product',
      url: '/news/category/product-news',
    },
    {
      content: 'news:redesign.nav.pov',
      url: '/news/category/pov',
    },
    {
      content: 'news:redesign.nav.pressReleases',
      url: '/news/category/press-releases',
    },
    {
      content: 'news:redesign.nav.aboutUs',
      url: '/news/about-us',
    },
  ];

  const mainNav = ({t, localizePath}: I18NOptions) =>
    headerLinks.map((hl) => ({
      content: t(hl.content),
      url: localizePath(hl.url),
    }));

  const toggleSearchOverlay = useCallback(
    () => setShowSearchOverlay((prevState) => !prevState),
    [],
  );

  const searchButton = () => (
    <button
      className={cn(
        'flex flex-row items-center pt-0 h-[73px] leading-5',
        'border-b-[3px] border-transparent hover:border-header-link-underline',
      )}
      title={tNews('news:nav.searchInWewsroom')}
      onClick={toggleSearchOverlay}
    >
      {tNews('news:redesign.nav.search')}
      &nbsp;
      <SearchIcon className="w-5" fill="white" />
    </button>
  );

  return (
    <>
      <GlobalHeader
        background={HeaderBackgroundColorScheme.TransparentDark}
        className="news-header"
        CustomDesktopCtaLinklist={searchButton()}
        CustomMobileCtaLinklist={searchButton()}
        CustomMobileMenuCtaLinklist={null}
        mainNavFn={mainNav}
      />
      {showSearchOverlay && (
        <li>
          <SearchOverlay handleClose={toggleSearchOverlay} />
        </li>
      )}
    </>
  );
}
