import type {LinksFunction} from '@remix-run/cloudflare';
import {Outlet, useMatches} from '@remix-run/react';

import {ColorSchemeEnum} from '@/enums';
import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import stylesheet from '@/stylesheets/news/styles.css?url';

import Header from './components/Header/Header';

export {loader} from '@/utils/server/loader/loader';

export const links: LinksFunction = () => [
  {rel: 'stylesheet', href: stylesheet},
];

export default function Layout() {
  const matches = useMatches();

  const {metaTitle, metaDescription}: any = matches[matches.length - 1]?.data;
  const layoutProps = {
    ...(metaTitle && {title: metaTitle}),
    ...(metaDescription && {metaDescription}),
  };

  return (
    <PageLayout
      {...layoutProps}
      isPureTitle
      header={() => <Header />}
      footer={
        <Footer
          colorScheme={ColorSchemeEnum.Dark}
          className="z-10 bg-transparent [&_.bg-black:not(#regionSelector)]:bg-transparent"
        />
      }
    >
      <Outlet />
    </PageLayout>
  );
}
