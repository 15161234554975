import {type SyntheticEvent} from 'react';
import {SearchIcon, XIcon} from '@shopify/polaris-icons';

import {useTranslations} from '@/hooks/useTranslations';
import {useKeyPress} from '@/hooks/useKeyPress';

import SearchGradient from '../Gradients/SearchGradient';

interface SearchOverlayProps {
  handleClose: (event: SyntheticEvent | Event) => void;
}

export default function SearchOverlay({handleClose}: SearchOverlayProps) {
  const {t} = useTranslations();

  useKeyPress('Escape', handleClose);

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 pt-[var(--header-height)] z-20 bg-black text-white noise">
      <div className="flex flex-col items-center relative container pt-8 pb-4 z-20">
        <div className="w-full flex justify-end">
          <button
            onClick={handleClose}
            title={t('news:nav.closeSearch')}
            className="w-[50px]"
          >
            <XIcon fill="white" />
          </button>
        </div>
        <div className="w-full">
          <form
            action="/news/search"
            className="w-full flex items-center border-b-2 border-search-underline"
          >
            <button
              type="submit"
              className="w-[30px]"
              title={t('news:nav.searchInWewsroom')}
            >
              <SearchIcon fill="white" />
            </button>
            <input
              type="text"
              name="q"
              placeholder={t('news:nav.searchNewsShopifyCom')}
              className="flex-1 py-4 pl-6 bg-transparent border-none text-lg leading-6 outline-none text-body placeholder:text-body max-576:pl-1"
            />
          </form>
        </div>
      </div>
      <SearchGradient />
    </div>
  );
}
